<template>
  <div class="general-report-by-flat" style="height: 100%">
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>
      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Основні</v-tab>
                <v-tab>Групування</v-tab>
              </v-tabs>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4-auto" style="height: auto !important; min-height: 215mm"
         :style="`zoom: ${scale / 100}`"
    >
      <div class="report" :style="`transform: scale(${zoom}); transform-origin: top left;`"
           style="height: 100%">
        <table>
          <caption>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 header-underline">
                <div class="rp-row rp-text-center" style="font-size: 1.1rem; font-weight: 500">
                  {{ organization.full_name }} (Підсумкова відомість внесків та податків)
                </div>
              </div>
              <div class="rp-col-12"></div>
              <div class="rp-col-12 text-center mt-2">
                {{ print_data.organization_details_with_account }}
              </div>
              <div class="rp-col-12 text-left mt-8">
                Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange(date_start, date_end) }}
              </div>
            </div>
            <div class="empty-row report-header" style="height: 20px;"></div>
          </caption>
          <thead>
            <tr id="header">
              <th style="min-width: 370px; width: 370px !important;">Назва внеску/податку</th>
              <th style="min-width: 80px; width: 80px; padding: 0;">
                Ставка
              </th>
              <th style="min-width: 140px; width: 140px; padding: 0;">
                База
              </th>
              <th style="min-width: 140px; width: 140px; padding: 0;">
                Результат
              </th>
            </tr>
          </thead>

          <tbody v-for="(item, idx) in items"
                 :key="`group-${idx}`"
          >
            <tr>
              <td style="min-width: 370px; width: 370px">
                {{ item.tax_name }}
              </td>
              <td style="min-width: 80px; width: 80px">{{ item.tariff | formatNumber('0.0000') }}</td>
              <td style="min-width: 140px; width: 140px">{{ item.base | formatNumber }}</td>
              <td style="min-width: 140px; width: 140px">{{ item.result | formatNumber }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import {formatDate, formatNumber} from "@/filters";
import accountingReportAPI from "@/utils/axios/accounting/reports"
import subdivisionAPI from "@/utils/axios/accounting/subdivision"
import personalAPI from "@/utils/axios/accounting/personal"
import {ALERT_SHOW} from "@/store/actions/alert";
import {getCurrentDate, getPrintDateRange, PORTRAIT_ZOOM_WIDTH} from "@/utils/reports";

export default {
  name: "SalaryTotalContributionAndTaxes",
  props: ['organization', 'print_data', 'global_settings', 'scale', 'date_start', 'date_end', 'generate', 'open_all', 'generate_xlsx', 'collapse_button', 'expand_button'],
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      showExpand: false,
      x: 0,
      y: 0,
      expand: null,
      zoom: 1,
      pages_data: {},
      filters: {
      },
      zoom_width: PORTRAIT_ZOOM_WIDTH,
      items: [],
      setting_dialog: this.global_settings,
      current_date: this.getCurrentDate(),
      settings_watcher: null,
      generate_watcher: null,
      generate_xlsx_watcher: null,
      open_all_watcher: null,
      local_response_people: this.print_data?.response_people ? this.print_data.response_people.slice() : [],
    }
  },
  methods: {
    onCityChange(payload) {
      this.filters.position_id = payload.value || null
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    formatDate,
    formatNumber,
    getCurrentDate,
    getPrintDateRange,
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        accountingReportAPI.general_report_by_flat_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `general_report_by_flat_${formatDate(this.date_start, 'MM_YYYY')}_${formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('xlsx_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
        }
        accountingReportAPI.salary_total_contribution_and_taxes(payload)
            .then(response => response.data)
            .then(data => {
              this.items = data
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
              this.$nextTick(() => {
                this.getOnFullPage()
              })
            })
      } else {
        this.$store.commit(ALERT_SHOW, {text: 'Місяць - має бути заповнений', color: 'error'})
      }
    },
    getTotal(col) {
      let total = 0

      if (this.by_grouping) {
        total = this.items.filter(item => item.level === 0 && !item.items).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      } else {
        total = this.items.filter(item => !item.level).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      }
      return total
    },
    getOnFullPage() {
      let width = 12
      const first_row = document.getElementById('header')
      first_row.childNodes.forEach(item => width += (item.clientWidth || 0))
      const gen_element = document.getElementsByClassName('report')[0]
      const scrollWidth = width * this.zoom
      const client_width = gen_element.clientWidth * this.zoom
      let zoom_width = 0
      let zoom = client_width / scrollWidth
      if (zoom === 0) zoom = 1
      if (zoom > 1) {
        zoom = 1
        zoom_width = PORTRAIT_ZOOM_WIDTH
      }

      if (zoom !== 1) {
        zoom_width = 1 / zoom * PORTRAIT_ZOOM_WIDTH
      }

      this.zoom = zoom
      this.zoom_width = zoom_width
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    watch_generate() {
      this.settings_watcher = this.$watch(
          'generate',
          {
            handler(payload) {
              if (payload) {
                this.generate_report()
              }
            }
          }
      )
    },
    watch_generate_xlsx() {
      this.generate_xlsx_watcher = this.$watch(
          'generate_xlsx',
          {
            handler(payload) {
              if (payload) {
                this.generate_xlsx_file()
              }
            }
          }
      )
    },
    watch_open_all() {
      this.open_all_watcher = this.$watch(
          'open_all',
          {
            handler(payload) {
              if (payload !== undefined) {
                this.showExpand = false
                const el = payload ? this.expand_button.$el : this.collapse_button.$el
                const cord = el.getBoundingClientRect()
                this.expand = payload
                this.x = cord.left
                this.y = cord.bottom + 2
                this.$nextTick(() => {
                  this.showExpand = true
                })
                this.$emit('clearOpenAll')
              }
            }
          }
      )
    },
  },
  created() {
    personalAPI.select()
        .then(response => response.data)
        .then(data => this.personal_items = data)
    subdivisionAPI.select()
        .then(response => response.data)
        .then(data => this.subdivision_items = data)

    this.watch_settings()
    this.watch_generate()
    this.watch_generate_xlsx()
    this.watch_open_all()
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
    if (this.generate_watcher) {
      this.generate_watcher()
    }
    if (this.open_all_watcher) {
      this.open_all_watcher()
    }
    if (this.generate_xlsx_watcher) {
      this.generate_xlsx_watcher()
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #cbcbcb;

table, th, td {
  border: 1px solid #cbcbcb;
  border-collapse: collapse;
}

th {
  background: #f3f3f3;
  font-weight: 500 !important;
  padding: 8px !important;
}
th, td {
  padding: 5px;
  font-size: 10.6px;
  font-weight: 400;
  width: 100px;
}
th.grow, td.grow {
  width: 180px;
  min-width: 180px;
}
tbody {
  tr {
    td {
      text-align: center;

      &:nth-child(1) {
        text-align: left;
        padding-left: 14px;
      }
    }
  }
}
:deep(td button:focus) {
  background: none !important;
}
.bg-level-0 {
  background: #e2e2e2;
}
.bg-level-1 {
  background: #f4f4f4;
}
.bg-level-2 {
  background: #f6f6f6;
}
.bg-level-3 {
  background: #f8f8f8;
}

//.page-a4-landscape-auto {
//  width: 1160px !important;
//  position: relative;
//}


@media print {
  * {
    float: none !important;
    clear: both;
  }

  .pages {
    height: auto !important;
    overflow: visible !important;
  }

  .page {
    visibility: visible !important;
    margin: 0 2px !important;
  }

  @page {
    margin: 0 0 5mm 0 !important;
  }
}

</style>